import React, { useState, useEffect, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  BodyWrapper,
  ButtonWrapper,
  FirstCol,
  Header,
  Input,
  InputWrapper,
  MainInfoWrapper,
  PasswordStrength,
  ResetBtn,
  SubmitBtn,
  InputWrapperHalf,
} from '../../../components/udb/commonStyle';
import { BankDropDown, BankInputField } from '../../../components/udb/Bankdetail';
import searchicon from '../../../images/search_icon.png';
import Intl from '../../../components/common/Intl';
import { addBankDetails, getAccountInfoData, updateBankDetails } from '../../../config/api';
import config from '../../../config/config';
import banks from '../sd/banks.json';
import { Notify } from '../../../components/Notification'
import { BankDataProps } from './Bankdetails';
import { UserContext } from '../index';
import UDBHeader from 'src/components/UDBHeader';
import { theme } from 'styled-tools';
import SEOContents from 'src/components/SEOContents';
import UserRegisterPopup from './UserRegisterPopup';
import { withAppContext } from 'src/store/initAppContext';
import { AccountInfoProps } from './AccountInfo';


type BankdetailsStateProps = {
  bank_name: string;
  account_holder_name: string;
  account_number: string;
  ifsc_code: string;
  branch_name?: string;
  bank_address?: string;
  remarks?: string;
  isDefault: boolean;
  mode: string;
}

type locationStateProps = {
  mode: string;
  data?: BankDataProps;
}

const defaultState: BankdetailsStateProps = {
  bank_name: '',
  account_holder_name: '',
  account_number: '',
  ifsc_code: '',
  branch_name: '',
  bank_address: '',
  remarks: '',
  isDefault: false,
  mode: 'create'
}

const defaultErrorState = {
  bank_name: false,
  account_holder_name: false,
  account_number: false,
  ifsc_code: false,
}


function Bankdetails(props: any) {
  const [bankState, setBankState] = useState(defaultState);
  const [errorState, setErrorState] = useState(defaultErrorState);
  const [refreshIfscDetails, setRefreshIfscDetails] = useState(true)
  const [bankDropdownDisable, setBankDropdownDisable] = useState(false)
  //one more state to handle reset on IFSC input
  const [apiActive, setApiActive] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();

  const { mode, data } = location.state as locationStateProps;

  const { app: { brandId },theme } = props
  const { config: { hidefistlastname } } = theme;

  // const { userInfo } = useContext(UserContext);
  // const {firstName,lastName} =userInfo;

  //Adding one state to show the user model to enter first name & last name
  const [showUserModel, setShowUserModel] = useState(false);
  const [userData, setUserData] = useState({} as AccountInfoProps);

  const getUserData = () => {
    getAccountInfoData().then(response => {
      const { data: { data } } = response;
      setUserData(data);
      //If user has not entered First name  & Last name need to 
      // show popup and ask user to enter his name
      if(!data.firstName || !data.lastName || !data.phoneNumberVerified) {
        setShowUserModel(true);
      }
      else {
        setShowUserModel(false);
      }
      //Esisting flow continues...
      const fullName = `${data.firstName} ${data.lastName}`;
      setBankState(prevS => ({ ...prevS, account_holder_name: fullName }))
    })
  }

  useEffect(() => {
    // for edit key name is not same so we need to do it first
    if (mode === 'update' && data.accountName) {
      prefilledBankDetail(data);
    }
    else if (!mode || mode === 'create') {
      getUserData()
      // const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
      // setBankState(prevS => ({ ...prevS, account_holder_name: fullName }))
    }
  }, []);

  const prefilledBankDetail = (data) => {
    setBankState({
      bank_name: data.bankName,
      account_holder_name: data.accountName,
      account_number: data.accountNumber,
      ifsc_code: data.ifscCode,
      branch_name: data.branchName,
      bank_address: data.bankAddress,
      remarks: data.remarks,
      isDefault: data.isDefault,
      mode: mode
    })
  }

  const resetBankDetail = (e) => {
    e.preventDefault();

    const resetHandler = () => {
      if (mode === 'update' && data.accountName) {
        prefilledBankDetail(data);
      }
      else {
        const fullName = `${userData.firstName} ${userData.lastName}`;
        setBankState({ ...defaultState, account_holder_name: fullName })
        setBankDropdownDisable(false)
      }
      setErrorState(defaultErrorState);
    }

    if (!apiActive) { resetHandler() }
    else { setTimeout(() => { resetHandler() }, 200) }

  }

  const changeHandler = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const _elm = e.target;
    const _val = _elm.value;
    const _name = _elm.name;

    setBankState(prevProp => ({ ...prevProp, [_name]: _val }));
    setErrorState(prevProp => ({ ...prevProp, [_name]: !_val }));
  }

  const changeIFSCHandler = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const _elm = e.target;
    const _val = _elm.value;

    setBankState(prevProp => ({ ...prevProp, ifsc_code: _val, branch_name: '', bank_address: '' }));
    setErrorState(prevProp => ({ ...prevProp, ifsc_code: !_val }));
    setRefreshIfscDetails(true)
  }

  const searchbankdetails = () => {
    let ifsc = bankState.ifsc_code;
    // const { mode } = locationState;
    const code = ifsc.slice(0, 4);

    if (ifsc) {
      ifsc = ifsc.toUpperCase();

      if (mode === 'update' && code !== bankState.bank_name) {
        setErrorState(prev => ({ ...prev, ifsc_code: true }));
        alert("Please enter IFSC code of the selected bank only")
        return;
      }

      if (refreshIfscDetails) {
        setRefreshIfscDetails(false);
        setApiActive(true);
        fetch(`${config.ifscApi}/${ifsc}`).then(res => {
          return res.statusText.toLowerCase() === 'ok' ? res.json() : null
        }).then(data => {
          if (data) {
            const isValidBank = banks.find(bank => bank.bank_code === data.BANKCODE);
            if (isValidBank) {
              setErrorState(prev => ({ ...prev, ifsc_code: false }));
              setBankState(prevProp => ({ ...prevProp, bank_name: data.BANKCODE, branch_name: data.BRANCH, bank_address: data.ADDRESS }));
              setBankDropdownDisable(true)
            }
            else {
              setErrorState(prev => ({ ...prev, ifsc_code: true }));
              alert('IFSC code is not valid or the bank is not in our bank list');
            }
          }
          else {
            Notify.error('Please check bank IFSC code and try again')
            setRefreshIfscDetails(true)
          }
        }).catch(e => {
          Notify.error('Something went wrong please try again')
          setRefreshIfscDetails(true)
        }).finally(() => {
          setApiActive(false)
        })
      }
    }
  }

  const submitBankDetails = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (errorState.bank_name || errorState.account_holder_name || errorState.account_number || errorState.ifsc_code) {
      return;
    }
    else {
      const { mode, ...props } = bankState;
      const params = JSON.stringify(props);

      if (mode === 'create') {
        addBankDetails(params).then(res => {
          if (res.data && res.data.success) {
            setBankState(defaultState);
            Notify.success('Bank details added successfully');
            navigate('/member/profile/bankdetails', { replace: true })
          }
        }).catch(e => {
          const { data } = e.response || {};
          let message = 'Something Went Wrong, Please Try Again'
          if (data && data.data.message) {
            message = data.data.message;

          }
          Notify.error(message);
        });
      }
      else if (mode === 'update') {
        updateBankDetails(params).then(res => {
          if (res.data && res.data.success) {
            setBankState(defaultState);
            Notify.success('Bank details updated successfully');
            navigate('/member/profile/bankdetails', { replace: true })
          }
        }).catch(e => {
          const { data } = e.response || {};
          let message = 'Something Went Wrong, Please Try Again'
          if (data && data.data.message) {
            message = data.data.message;

          }
          Notify.error(message);
        });

      }
    }
  }

  const setDefaultBanckHandel = () => {
    setBankState(prevProp => ({ ...prevProp, isDefault: !prevProp.isDefault }));
  }

  const numberHandler = (e) => {
    const regExp = /^\d+$/;
    const preValue = bankState.account_number;
    const _elm = e.target;
    const val = _elm.value;
    const _val = val ? (regExp.test(val) ? val : preValue) : '';
    const _name = _elm.name;

    setBankState(prevProp => ({ ...prevProp, [_name]: _val }));
    setErrorState(prevProp => ({ ...prevProp, [_name]: (!_val || _val.length < 8) }));
  }

  const openChatBox = () => {
    const isChat = window.$zoho;
    if (typeof isChat === 'object') {
      window.$zoho.salesiq.floatwindow.visible("show");
    }
  }

  const submitBtndiabledState = !!(bankState.bank_name && bankState.account_holder_name && bankState.account_number && bankState.ifsc_code && bankState.branch_name);
  const submitBtnState = !submitBtndiabledState ? { disabled: true } : {}
  const makeDisabled = bankState.mode === 'update' ? { readOnly: true, disabled: true } : {}

  return (
    <>
      {(hidefistlastname && showUserModel) ? (
        <UserRegisterPopup userData={userData} successhandler={getUserData} />
      ) : (
        <BankDetailsWrapper>
          <MainInfoWrapper>
            <SEOContents pageName="memberProfileBankDetails" />
            <Header>
              <FirstCol>
                <UDBHeader title="DASHBOARD@BANKDETAILS" />
              </FirstCol>
            </Header>
            <form onSubmit={submitBankDetails}>
              <BodyWrapper>
                <InputWrapper className={errorState.bank_name ? 'error' : ''}>
                  <BankDropDown data={banks} onChange={changeHandler} value={bankState.bank_name} placeholder='Select Bank Name' id="bankName" name="bank_name" disabled={bankDropdownDisable} req {...makeDisabled} />
                </InputWrapper>

                <InputWrapper className={errorState.account_holder_name ? 'error' : ''}>
                  <BankInputField placeholder={bankState.account_holder_name == "null null" ? "Update your full name in profile section" : "Account Holder Name"} value={bankState.account_holder_name === "null null" ? "" : bankState.account_holder_name} readOnly onChange={changeHandler} name="account_holder_name" req {...makeDisabled} />
                </InputWrapper>

                <InputWrapperHalf className={errorState.account_number ? 'error' : ''}>
                  <BankInputField placeholder="Account Number" maxLength={25} value={bankState.account_number} name="account_number" onChange={(e) => numberHandler(e)} req {...makeDisabled} />
                </InputWrapperHalf>

                <InputWrapperHalf className={errorState.ifsc_code ? 'error' : 'space'}>
                  <BankInputField placeholder="IFSC Code" value={bankState.ifsc_code} name="ifsc_code" className='pr-5' onChange={changeIFSCHandler} onBlur={searchbankdetails} req />
                  <SearchIcon onClick={searchbankdetails}></SearchIcon>
                </InputWrapperHalf>

                <InputWrapperHalf>
                  <BankInputField placeholder="Bank Branch" name="branch_name" onChange={changeHandler} readOnly value={bankState.branch_name} {...makeDisabled} />
                </InputWrapperHalf>

                <InputWrapperHalf >
                  <BankInputField placeholder="Bank Address" name="bank_address" onChange={changeHandler} readOnly value={bankState.bank_address} />
                </InputWrapperHalf>

                <InputWrapperHalf>
                  <BankInputField placeholder="Remarks" maxLength={26} name="remarks" onChange={changeHandler} value={bankState.remarks} />
                </InputWrapperHalf>

                <RadioBtnWrapper>
                  <BtnText>
                    <Input type="radio" name='isDefault' checked={bankState.isDefault} onClick={setDefaultBanckHandel} />
                    Set Default Account</BtnText>
                </RadioBtnWrapper>

                <PasswordStrength>
                  <span><Intl langKey="DASHBOARD@Messageshow" /> </span>
                  <CustomeServiceLink onClick={openChatBox}><Intl langKey="DASHBOARD@customrService" /> </CustomeServiceLink>
                  {/* <Link to={''}><Intl langKey="DASHBOARD@customrService" /> </Link> */}
                </PasswordStrength>

                <ButtonWrapper>
                  <SubmitBtn {...submitBtnState} type="submit">{bankState.mode === 'update' ? <Intl langKey="DASHBOARD@UpdateAcc" /> : <Intl langKey="DASHBOARD@AddAcc" />}</SubmitBtn>
                  <ResetBtn onClick={resetBankDetail}><Intl langKey="DASHBOARD@reset" /> </ResetBtn>
                </ButtonWrapper>
              </BodyWrapper>
            </form>
          </MainInfoWrapper>
        </BankDetailsWrapper>
      )}
    </>
  )
}

export default withTheme(withAppContext(Bankdetails));


const SearchIcon = styled.span`
    width: 15px;
    height: 15px;
    display: inline-block;
    letter-spacing: 0.01em;
    color: #929292;
    font-size: 13px;
    border: none;
    background: none;
    position: absolute;
    right: -7px;
    cursor: pointer;
    background-image: url(${searchicon});
    /* background-size: cover; */
    background-repeat: no-repeat;
    transform: rotate(270deg);
    z-index: 9;
    padding: 20px 25px;
    background-position: center;
    border-radius: 0px 0px 3px 3px;
    display: inline-block;
    background-color: ${theme('palette.BankDetailsBtnColor')};

    :hover {
        background-color: ${theme('palette.BankDetailsBtnHoverColor')};
    }
`;
const BtnText = styled.label`
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color:${theme('palette.defaultAcc')};
    font-weight: 600;
    font-size: 11px;
`;
const RadioBtnWrapper = styled.div`
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    // background: radial-gradient(50% 50% at 50% 50%,rgba(255,255,255,0.56) 0%,rgba(255,255,255,0.56) 100%);
    background: #fff;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 50px rgb(194 199 233 / 50%);
    border-radius: 7px;

    ${Input} {
        width: 18px!important;
        height: 18px!important;
        border: 1px solid #4643C2;
        margin: 0;
        margin-right: 5px;
    }
`;
const BankDetailsWrapper = styled.div`
    width: 100%;
    
    ${PasswordStrength} {
        display: inline-block;
        padding: 6px 0px;

        a {
            color: #FF5A76;
        }
    }

    .error {
      label {
        border:1px solid red!important;
        background: #efdfdf;
      }

    }

    ${InputWrapperHalf} {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      box-shadow: none;
    }
`;

const CustomeServiceLink = styled.span`
color: #FF5A76;
cursor: pointer;
`;